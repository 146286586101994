<template>
    <div>
        <div class="policy_content" v-html="info.content"></div>
        <div v-if="info.origin != null" class="policy_origin">
            <div>来源: {{info.origin}}</div>
            <a  href="info.originUrl">原文链接</a>
        </div>

    </div>
</template>

<script>
import { getPolicyDetail } from '@/api/policy'
import to from '@/utils/to'
import { SLOGAN, LOGO_URL } from '@/utils/misc'
import wxShareMixin from '@/mixins/wx_share_mixin'

export default {
    data() {
        return {
            id: '',
            info: {}
        }
    },
    mixins: [wxShareMixin],
    mounted() {
        this.id = this.$route.query.id
        this.getData()
    },
    methods: {
        async getData() {
            this.$showLoading('加载中...')
            let [err, res] = await to(getPolicyDetail(this.id))
            this.$hideLoading()
            if (err) {
                this.$showToast('加载失败')
                return
            }

            this.info = res

            const shareCfg = {
                title: this.info.title,
                desc: SLOGAN,
                link: location.href,
                imgUrl: LOGO_URL
            }

            this.getWS(shareCfg)
        }
    }
}
</script>

<style scoped>
.policy_content {
    padding: 16px;
    font-size: 16px;
    line-height: 1.5;
    background-color: #fff;
}

.policy_origin {
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

</style>

<style>
.policy_content p {
    margin-bottom: 20px;
}

.policy_content img {
    width: 100%;
}
</style>